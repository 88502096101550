<template>
  <div class="plansPage">
    <pageHeader />

    <div class="content sec-padding">
      <v-container fluid>
        <v-row class="align-center">
          <v-col cols="12" md="12" lg="4" class="text-center">
            <h2 class="main-color main-title">اختر خطة اشتراكك</h2>

            <div class="duration-btns">
              <v-btn
                class="monthly"
                @click="updatePlanDuration('monthly')"
                :class="!annually ? 'active' : ''"
                large
                >شهري</v-btn
              >
              <v-btn
                class="annual"
                :class="annually ? 'active' : ''"
                @click="updatePlanDuration('annually')"
                large
                >سنوي</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" md="12" lg="8" class="pa-0">
            <v-row v-if="plans.length > 0" class="fill-height">
              <v-col
                cols="12"
                sm="4"
                v-for="(plan, index) in plans"
                :key="index"
              >
                <v-card
                  class="mx-auto"
                  max-width="95%"
                  :class="plan.current_plan ? 'active-plan' : ''"
                  outlined
                >
                  <v-card-text>
                    <div class="plan pa-4 pa-sm-0 pa-md-4">
                      <div class="price text-center">
                        <p class="price-title black-color">{{ plan.name }}</p>
                        <p class="price-value">
                          <span class="currency black-color">ر.س</span>
                          <span class="num">{{
                            annually ? plan.annual_price : plan.monthly_price
                          }}</span>
                          <span class="duration" v-if="annually">/ سنة</span>
                          <span class="duration" v-if="!annually">/ شهر</span>
                        </p>
                      </div>
                      <!-- <p class="text-center desc">
                        هذه هي خطتنا الأكثر تكلفة للبدء!
                      </p> -->
                      <ul class="unstyled pl-0" v-if="plan.features.length > 0">
                        <li class="black-color mb-2" v-if="index > 0">
                          <p class="mb-0 d-flex align-baseline">
                            <span
                              class="icon d-inline-block pl-2"
                              style="visibility: hidden"
                              ><v-icon color="#46101f" large
                                >mdi-check</v-icon
                              ></span
                            >
                            <span class="text allPrev">
                              كل المميزات السابقة بالإضافة الى
                            </span>
                          </p>
                        </li>
                        <li
                          class="black-color mb-2"
                          v-for="(feature, index) in plan.features"
                          :key="index"
                        >
                          <p class="mb-0 d-flex align-baseline">
                            <span class="icon d-inline-block pl-2"
                              ><v-icon
                                :color="
                                  plan.current_plan ? '#a53860' : '#46101f'
                                "
                                >mdi-check</v-icon
                              ></span
                            >
                            <span class="text"> {{ feature }} </span>
                          </p>
                        </li>
                      </ul>
                      <div
                        class="spacer"
                        :class="plan.current_plan ? 'd-none' : ''"
                      ></div>
                      <div class="selectPlan text-center">
                        <v-btn
                          class="select-btn"
                          v-if="!plan.current_plan && index > currentPlanIndex"
                          @click="selectPlan(plan.id)"
                          large
                          >اختر الخطة</v-btn
                        >
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <subscribeDialog :dialog="SubDialog" @closeDialog="closeSubDialog" />
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import { mapGetters } from "vuex";
import subscribeDialog from "@/components/subscribeDialog.vue";

export default {
  name: "pricingList",
  components: { pageHeader, subscribeDialog },

  data() {
    return {
      plans: [],
      annually: true,
      uuid: "",
      currentPlanIndex: "",
      SubDialog: false,
      loggedIn: localStorage.loggedIn ? true : false,
      type: localStorage.type,
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    closeSubDialog() {
      this.SubDialog = false;
    },
    getAllPlans() {
      this.$http
        .get(this.base_url + "/front/getPlans?uuid=" + this.uuid, {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.plans = response.data.data;

            this.plans.forEach((plan, index) => {
              if (plan.current_plan == true) {
                this.currentPlanIndex = index;
              }
            });
          }
        });
    },
    updatePlanDuration(duration) {
      if (duration == "annually") {
        this.annually = true;
      } else {
        this.annually = false;
      }
    },

    selectPlan(planId) {
      let planType = this.annually ? "annually" : "monthly";
      if (this.uuid || (this.loggedIn && this.type == "salon")) {
        let uuid = this.uuid ? this.uuid : "";
        this.$router.push(
          "/payment?planId=" + planId + "&type=" + planType + "&uuid=" + uuid
        );
      } else {
        this.SubDialog = true;
      }
    },
  },
  mounted() {
    this.uuid = this.$route.query.uuid ? this.$route.query.uuid : "";
    this.getAllPlans();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.black-color {
  color: #121d2a;
}

.plansPage {
  .content {
    margin: 2rem;
    .duration-btns {
      max-width: 60%;
      margin: 0 auto;
      // border: 1px solid #ccc;
      border-radius: 30px;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem !important;
      box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.3);
      .annual,
      .monthly {
        box-shadow: unset !important;
        background-color: transparent;
        border: 0 !important;
        border-radius: 30px;
        width: 140px;
      }

      .annual.active,
      .monthly.active {
        background-color: $second-color !important;
        color: #ffffff !important;
      }
    }
    .plan {
      .allPrev {
        color: #677177;
      }
      .price {
        .price-title {
          font-size: 2rem !important;
        }
        .price-value {
          padding: 1rem 0;

          .num {
            font-size: 3rem;
            color: #121d2a;
            padding: 0 10px;
          }
        }
      }
      .desc {
        padding-bottom: 1rem;
      }
      .selectPlan {
        position: absolute;
        bottom: 30px;
        right: 18%;
        .select-btn {
          width: 200px;
          background-color: transparent !important;
          border-radius: 12px;
          border: 1px solid #ccc;
          box-shadow: unset;
          margin-top: 1rem;
          transition: 0.3;
        }

        .select-btn:not(.active-btn):hover {
          color: $second-color !important;
          border-color: $second-color !important;
        }

        .active-btn {
          background-color: $second-color !important;
          border: 1px solid $second-color;
          color: #ffffff !important;
        }
      }
    }

    .active-plan {
      .price-title,
      .price-value,
      .num,
      .desc,
      .currency,
      .allPrev,
      li {
        color: #ffffff !important;
      }
    }
  }
}

.spacer {
  height: 90px;
}
@media (max-width: 600px) {
  .content {
    margin: 1rem !important;
    .duration-btns {
      max-width: 100% !important;
      margin-bottom: 1rem !important;
      .annual,
      .monthly {
        width: 140px;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .selectPlan {
    right: 14% !important;
    .select-btn {
      width: 150px !important;
    }
  }
}
</style>
