<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="50%"
      style="box-shadow: unset !important"
    >
      <v-card id="subscribe-dialog">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="left-col d-none d-sm-block pa-0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <div class="slogan">
                      <h2 class="slogan1 white--text">انضم لاكبر مجتمع</h2>
                      <h2 class="slogan2 second-color">تجميل فى العالم</h2>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6" class="right-col pa-0">
              <v-card-text class="text-center px-4 px-sm-8">
                <h2 class="mb-8">
                  <strong> هذا الاشتراك خاص بالصالونات. </strong>
                </h2>
                <div class="login-sec mb-8" v-if="!loggedIn">
                  <p>
                    لتسجيل الدخول
                    <router-link to="/login" class="second-color">
                      إضغط هنا</router-link
                    >
                    أو لإنشاء حساب صالون
                    <router-link to="/registration/salon" class="second-color">
                      إضغط هنا</router-link
                    >
                  </p>
                </div>
                <div class="login-sec mb-8" v-if="loggedIn && type == 'client'">
                  <p>للمتابعة يرجى تسجيل الدخول كصالون</p>
                </div>
                <!-- <div class="appLinks">
                  <a
                    href="https://apps.apple.com/eg/app/jamalouk/id1602674897"
                    target="_blank"
                  >
                    <img
                      src="../assets/A.png"
                      alt=""
                      class="d-inline-block app-img"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.shifters.jamalouk"
                    target="_blank"
                  >
                    <img
                      src="../assets/G.png"
                      alt=""
                      class="d-inline-block app-img"
                    />
                  </a>
                  <a
                    href="https://appgallery.huawei.com/app/C105312407a"
                    target="_blank"
                  >s
                    <img
                      src="../assets/H.png"
                      alt=""
                      class="d-inline-block app-img"
                    />
                  </a>
                </div> -->
                <div class="close-action text-center mt-4">
                  <v-btn class="main-btn login-btn" @click="closeDialog">
                    اغلاق
                  </v-btn>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "subscribeDialog",
  props: ["dialog"],
  data() {
    return {
      loggedIn: localStorage.loggedIn ? true : false,
      type: localStorage.type,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

#subscribe-dialog {
  //   padding: 50px 0 !important;
  background-color: transparent;
  .right-col {
    // height: 630px;
    padding: 100px 0;
    border-top: 5px solid #a53860;
    border-radius: 0 0 0 60px;
    padding: 1rem 3rem;
    background-color: #fff;
    margin-bottom: 10px !important;
    padding-top: 100px !important;
    padding-bottom: 25px !important;
    .appLinks {
      .app-img {
        max-width: 130px !important;
        margin-bottom: 12px;
        margin-right: 5px;
        margin-left: 5px;
        border: 1px solid #ccc;
        border-radius: 12px;
      }
    }
    h2 {
      font-size: 1.5rem !important;
    }
  }
  .left-col {
    // height: 630px;
    border-bottom: 5px solid #fff;
    border-radius: 0 70px 0 0;
    padding: 1rem 2rem;
    background-color: #2d3533;
    margin-top: 10px;

    .slogan {
      position: absolute;
      top: 40%;
      right: 10%;
      font-size: 1.5rem;
      font-weight: bold;
      .slogan1 {
        // font-size: 2rem;
        margin-bottom: 3rem;
      }
      .slogan2 {
        // font-size: 2rem;
      }
    }
  }
}
</style>
